<!--
 * @Author: xr
 * @Date: 2022-01-26 09:49:15
 * @LastEditors: xr
 * @LastEditTime: 2022-03-07 10:52:34
 * @FilePath: \postStation-office\src\views\resource.vue
-->
<template>
  <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between" style="padding:0px 10px;">
        <a-col :span="7" style="padding-left: 0px">
          <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
          <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
        </a-col>
        <a-col :span="17" style="min-height:62vh;">
          <div class="outer-box sub-component-background-color">
            <div class="inner-box"><span style="font-size:24px;">项目库</span></div>
            <template>
              <a-empty v-if="JSON.stringify(tableData)==='{}'" class="empty-data"/>
              <div class="project-warehouse" v-for="(item,index) in tableData" :key="index" >
                <span class="type-title"/>
                <span class="type-name" style="font-size: 20px;">{{item[0].itemText}}</span>
                <span class="more" @click="jump(item,index)">更多>></span>
                <content-box :scroolData="item" @detail="projectDetails"/>
              </div>
            </template>
          </div>
        </a-col>
      </a-row>
      <!-- 友情链接 -->
      <link-box />
    </div>
  </div>
</template>
<script>
import linkBox from '@comp/layout/link'
import contentBox from '@comp/scroll/verticalScroll'
import FindDemand from '@comp/findDemand'
import { getProjectList, domainQueryId } from '@/api/project'
import { getDict } from '@/api/index'
import { Station } from '@/mixins/station'

export default {
  name: 'Resource',
  mixins: [Station],
  components: {
    linkBox,
    contentBox,
    FindDemand
  },
  data() {
    return {
      tableData: [],
      technicalProjectContent: []
    }
  },
  methods: {
    /**
     * @description: 跳转list
     * @param {String} dic
     */
    jump(dic, index) {
      if (this.stationId !== '') {
        this.$router.push({ name: 'resourceList', params: { dic: dic }, query: { stationId: this.stationId, mainResearchAreas: index }})
      } else {
        this.$router.push({ name: 'resourceList', params: { dic: dic }, query: { mainResearchAreas: index }})
      }
    },
    /**
     * @description:项目需求详情
     */
    projectDetails(id) {
      domainQueryId({ id: id }).then(res => {
        if (this.stationId !== '') {
          this.$router.push({ name: 'detail', params: res.result, query: { stationId: this.stationId }})
        } else {
          this.$router.push({ name: 'detail', params: res.result })
        }
      })
    },
    getList() {
      // 判断是否为子站
      this.getStationId()
      const params = {
        stationId: this.stationId,
        type: '1'
      }
      getProjectList(params).then(res => {
        this.tableData = res.result
      })
    }
  },
  activated() {
    // this.stationId = this.$route.query.stationId
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
    this.getList()
  }
}
</script>
<style lang="scss" scoped>
@import "~@assets/css/border.scss";
@import "~@assets/css/scroll.css";
@import "../assets/css/newsInformation.css";

// .project-warehouse .type-name {
//   font-size: 20px;
// }
</style>
