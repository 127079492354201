<!--
 * @Author: xr
 * @Date: 2022-02-14 09:23:55
 * @LastEditors: xr
 * @LastEditTime: 2022-03-03 14:33:08
 * @FilePath: \postStation-office\src\components\scroll\verticalScroll.vue
-->
<template>
  <div>
    <div class="header-box">
      <span :class="'title'+index+ ' tx'" v-for="(item,index) in titleData" :key="index">{{item}}</span>
    </div>
    <a-empty v-if="scroolData.length===0" class="empty-data"/>
    <vue-seamless-scroll v-else :data="scroolData" class="warp" :class-option="classOption">
      <ul class="item">
        <li v-for="(item, index) in scroolData" :key="index" @click="details(item.id)">
          <span v-text="item.productName" class="title0 text-over-ellipsis"/>
          <span v-text="item.applicationArea_dictText" class="title1"/>
          <span v-text="item.price" class="title2"/>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: 'VerticalScroll',
  components: {
    vueSeamlessScroll
  },

  computed: {
    classOption() {
      return {
        openWatch: true, // 开启数据实时监控刷新dom
        step: 0.4
      }
    }
  },
  props: {
    titleData: {
      type: Array,
      default: () => ['项目名称', '应用领域', '价格(万元)']
    },
    scroolData: {
      type: Array
    }
  },
  data() {
    return {
      listData: []
    }
  },
  methods: {
    /**
     * @desciption:跳转
     * @param {string} id
     */
    details(id) {
      this.$emit('detail', id)
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
.header-box{
  background: #F6FAFF;
  display:flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  font-weight: bold;
  border-top: 2px solid #B9BEC4;
}
.title0{
  width:45%;
}
.tx{
  text-align: center;
}
.title1{
  width:35%;
  text-align: center;
}
.title2{
  width:20%;
  text-align: center;
}
.warp {
  height: 114px;
  width: 100% ;
  margin: 0 auto;
  overflow: hidden;
  color: #004682;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    li {
      cursor: pointer !important;
      display: block;
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      cursor:pointer;
      &:hover{
        background: #F5F5F5;
      }
    }
  }
}
</style>
